export let baseUrl = process.env.REACT_APP_BASE_URL;
// export let baseUrl = "https://axproperty-api-new.onrender.com/api/";
// export let baseUrl = "https://axproperty-api-project-branch.onrender.com/api/";
// export let baseUrl = "http://192.168.0.162:9000/api/";
// let baseUrl = "https://293b-2409-4085-879f-e246-818-2c67-2651-66a.ngrok-free.app/api/"

const Apis = {
  baseUrl,
  login: baseUrl + "auth/login",
  users: baseUrl + "user",
  leads: baseUrl + "lead",
  meeting: baseUrl + "meeting",
  team: baseUrl + "team",
  booking: baseUrl + "booking",
  developer: baseUrl + "developer",
  project: baseUrl + "project",
  getLeadById: baseUrl + "lead/getLeadById",
  meetingDetailsById: baseUrl + "meeting/meetingDetailsById",
  dashboard: baseUrl + "dashboard",
  meetingDashboard: baseUrl + "dashboard/meetings",
  bookingDashboard: baseUrl + "dashboard/bookings",
  getBookingDetailsById: baseUrl + "booking/getBookingDetailsById",
  bookingPayment: baseUrl + "booking/updatePayment",
  getMeetingForBooking: baseUrl + "meeting/getMeetingForBooking",
  assignUsers: baseUrl + "user/assigningUsers",
  developerUpdateById: baseUrl + "developer/developerUpdateById",
  userUpdateById: baseUrl + "user/userUpdateById",
  leadUpdateById: baseUrl + "lead/leadUpdateById/",
  addNote: baseUrl + "lead/addNotes",
  statusUpdateById: baseUrl + "lead/statusUpdateById/",
  teamUpdatedById: baseUrl + "team/teamUpdateById/",
  leadAssignById: baseUrl + "lead/leadAssignById",
  assignedHistoryById: baseUrl + "lead/getAssignedHistory",
  statusHistoryById: baseUrl + "lead/getStatusHistory",
  updateMeetingById: baseUrl + "meeting/updateMeetingById/",
  deleteMeetingsById: baseUrl + "meeting/deleteMeetingsById",
  bookingApprovedById: baseUrl + "booking/bookingApprovedById",
  bookingRejectedById: baseUrl + "booking/bookingRejectedById",
  deleteLeadsByIds: baseUrl + "lead/deleteLeadsByIds",
  deleteBookingsById: baseUrl + "booking/deleteBookingsById",
  changePassword: baseUrl + "user/changePassword",
  setPassword: baseUrl + "user/setPassword",
  meetingReschedule: baseUrl + "meeting/reScheduleMeetingById",
  meetingConduct: baseUrl + "meeting/conductById",
  notificationSeenById: baseUrl + "user/notificationSeenById",
  getNotificationById: baseUrl + "user/getNotificationById",
  getCommissionData: baseUrl + "booking/getCommissionData",
  filterLeads: baseUrl + "lead/filterLeads",
  filterMeetings: baseUrl + "meeting/filterMeetings",
  filterBookings: baseUrl + "booking/filterBookings",
  meetingOtpVerify: baseUrl + "meeting/meetingOtpVerify",
  meetingOtpGenerate: baseUrl + "meeting/meetingOtpGenerate",
  caseFileUpload: baseUrl + "booking/caseFileUpload",
  meetingHistoryById: baseUrl + "meeting/getLatestMeetings",
  topPerformance: baseUrl + "booking/topPerformance",
  summaryOfEOI: baseUrl + "booking/summaryOfConfirmedBusinessBooking",
  topDeveloper: baseUrl + "booking/topDeveloper",
  leadPoolRestriction: baseUrl + "user/updatePoolRestriction",
  inactiveUser: baseUrl + "booking/reportOfInActiveEmployee",

  /* Hr Management */
  hrmsUser: baseUrl + "hrms/user",
  getTeamBySrManager: baseUrl + "hrms/user/getTeamsBySrManager",
  getAvailableRoles: baseUrl + "hrms/user/getAvailableRoles",
  hrmsLeave: baseUrl + "hrms/leave",
  getHrmsUserDetails: baseUrl + "hrms/user/getUserDetailsById",
  setAdvanceLeaves: baseUrl + "hrms/user/setAdvanceLeaves",
  getHrmsLeaveDetails: baseUrl + "hrms/leave/getDetailsById",
  sendInvitation: baseUrl + "hrms/user/sendInvitation",
  accessUpdate: baseUrl + "hrms/user/accessUpdate",
  updateUser: baseUrl + "hrms/user/updateUserById",
  hrmsLeaveDasbordData: baseUrl + "hrms/leave/dashboardData",
  hrmsLeaveApprove: baseUrl + "hrms/leave/leaveApproveById",
  hrmsLeaveReject: baseUrl + "hrms/leave/leaveRejectById",
  hrmsUserApprove: baseUrl + "hrms/user/approveById",
  hrmsUserDasbordData: baseUrl + "hrms/user/dashboardData",
  todaysMeetings: baseUrl + "meeting/getTodaysMeetings",
  getDeveloperOptions: baseUrl + "developer/getDeveloperOptions",
  remotePunch: baseUrl + "hrms/att/remotePunch",
  getAttendance: baseUrl + "hrms/att",
  hrmsRaiseIssues: baseUrl + "hrms/att/issueRaiseById",
  getAttDetails: baseUrl + "hrms/att/getDetailsById",
  attUpdateStatus: baseUrl + "hrms/att/updateStatusById",
  // Attendance Dashboard api
  hrmsDashboard: baseUrl + "hrms/dashboard",
  hrmsLeaveCancel: baseUrl + "hrms/leave/leaveCancelById",

  updateProject: baseUrl + "Project/updateProject",
  deleteProject: baseUrl + "project/deleteProjects",

  //expense cat api
  expanseList: baseUrl + "lead/get-all-category",
  expenseCatList: baseUrl + "lead/getExpenses",
  addExpenseCat: baseUrl + "lead/add-category",
  deleteExpenseCat: baseUrl + "lead/delete-category",
  expenseCatDetails: baseUrl + "lead/get-category",
  expenseCatUpdate: baseUrl + "lead/update-category",

  //expense list api
  addExpenseList: baseUrl + "lead/add-expense",
  deleteExpenseList: baseUrl + "lead/delete-expenses",
  expenseListDetails: baseUrl + "lead/get-expenses",
  updateExpense: baseUrl + "lead/update-expenses",

  //invoice list api
  invoiceList: baseUrl + "lead/getInvoices",
  invoiceDelete: baseUrl + "lead/deleteInvoice",
  invoiceDetails: baseUrl + "lead/getInvoiceById",
  invoiceCreate: baseUrl + "lead/add-invoice",
  invoiceBookingById: baseUrl + "lead/bookings",
  invoiceReceivedById: baseUrl + "lead/uploadReceivedStatus",
  invoiceUpdateById: baseUrl + "lead/updateInvoice",

  //expense team role wise list
  teamRoleExpense: baseUrl + "lead/getUserByRole",

  //lead pool api
  leadPool: baseUrl + "lead/leadPoolList",
  leadPoolClaim: baseUrl + "lead/claimLead",

  //Incentive
  incentive: baseUrl + "incentive/getIncentives",
  incentiveDetails: baseUrl + "incentive/details",
  incentivePayout: baseUrl + "incentive/payIncentive",

  // Confirmed Business
  confirmedBusiness: baseUrl + "booking/confirmedBusinessBooking",
  expressionInterest: baseUrl + "booking/executedBusinessBooking",

  //Lead Note edit and delete
  editNote: baseUrl + "notes",
  deleteNote: baseUrl + "notes",

  //HRMS User Devices delete
  deleteDevices: baseUrl + "hrms/user/update-devices"

};

export default Apis;
