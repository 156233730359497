import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";
import { API_AXIOS } from "../../http/interceptor";

export const bookingsApi = createApi({
  reducerPath: "bookingList",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Bookings"],
  endpoints: (builder) => ({
    getBookingData: builder.query({
      // query: ({
      //   page = 1,
      //   limit,
      //   status,
      //   pagination = true,
      //   type,
      //   name,
      //   startDate,
      //   endDate,
      //   srManager,
      //   manager,
      //   assistantManager,
      //   teamLead,
      //   agent,
      //   clientName,
      //   clientMobile,
      //   clientEmail,
      //   relationshipManager,
      //   projectName,
      //   inputStatus,
      //   developer,
      //   paymentStatus,
      //   paymentMode,
      //   token,
      //   search,
      // }) => ({
      //   url: Apis.booking,
      //   params: {
      //     page,
      //     limit,
      //     status,
      //     pagination,
      //     type,
      //     name,
      //     startDate,
      //     endDate,
      //     srManager,
      //     manager,
      //     assistantManager,
      //     teamLead,
      //     agent,
      //     clientName,
      //     clientMobile,
      //     clientEmail,
      //     relationshipManager,
      //     projectName,
      //     inputStatus,
      //     developer,
      //     token,
      //     search,
      //   },
      // }),
      queryFn: async ({
        page = 1,
        limit,
        status,
        pagination = true,
        type,
        name,
        startDate,
        endDate,
        srManager,
        manager,
        assistantManager,
        businessStatus,
        teamLead,
        agent,
        clientName,
        clientMobile,
        clientEmail,
        relationshipManager,
        projectName,
        inputStatus,
        developer,
        paymentStatus,
        paymentMode,
        token,
        search,
      }) => {
        try {
          const { data } = await API_AXIOS.get(Apis.booking, {
            params: {
              page,
              limit,
              status,
              pagination,
              type,
              name,
              startDate,
              endDate,
              srManager,
              manager,
              businessStatus,
              assistantManager,
              teamLead,
              agent,
              clientName,
              clientMobile,
              clientEmail,
              relationshipManager,
              projectName,
              inputStatus,
              developer,
              token,
              search,
              paymentStatus,
              paymentMode,
            },
          });

          return { data };
        } catch (error) {
          return {
            error: error.response ? error.response.data : error.message,
          };
        }
      },
      providesTags: ["Bookings"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetBookingDataQuery } = bookingsApi;
