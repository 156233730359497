import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithAuth } from "./hrmsNotifications";
import Apis from "../../utils/apis";
import { API_AXIOS } from "../../http/interceptor";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const leadsApi = createApi({
  reducerPath: "leadList",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Leads"],
  endpoints: (builder) => ({
    getLeadsData: builder.query({
      query: ({
        page = 1,
        limit,
        type,
        status,
        individual,
        search,
        pagination = true,
        srManager,
        manager,
        assistantManager,
        teamLead,
        agent,
        startDate,
        endDate,
        skipType,
        projectId,
      }) => ({
        url: Apis.leads,
        params: {
          page,
          limit,
          type,
          status,
          individual,
          search,
          pagination,
          srManager,
          manager,
          assistantManager,
          teamLead,
          agent,
          startDate,
          endDate,
          skipType,
          projectId,
        },
      }),
      providesTags: ["Leads"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetLeadsDataQuery } = leadsApi;

export const assignedHistory = createApi({
  reducerPath: "assignedHistory",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Assigned"],
  endpoints: (builder) => ({
    getAssignedHistory: builder.query({
      query: (id) => ({
        url: `${Apis.assignedHistoryById}/${id}`,
      }),
      providesTags: ["LeadDetail"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetAssignedHistoryQuery } = assignedHistory;

export const statusHistory = createApi({
  reducerPath: "statusHistory",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Lead Status"],
  endpoints: (builder) => ({
    getStatusHistory: builder.query({
      query: (id) => ({
        url: `${Apis.statusHistoryById}/${id}`,
      }),
      providesTags: ["LeadDetail"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetStatusHistoryQuery } = statusHistory;

export const meetingHistory = createApi({
  reducerPath: "meetingHistory",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Meeting History"],
  endpoints: (builder) => ({
    getMeetingHistory: builder.query({
      query: (id) => ({
        url: `${Apis.meetingHistoryById}/${id}`,
      }),
      providesTags: ["MeetingHistory"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetMeetingHistoryQuery } = meetingHistory;

export const topPerformance = createApi({
  reducerPath: "topPerformance",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Top Performance"],
  endpoints: (builder) => ({
    getTopPerformance: builder.query({
      query: ({ startDate, endDate, count }) => ({
        url: `${Apis.topPerformance}`,
        params: {
          startDate,
          endDate,
          count,
        },
      }),
      providesTags: ["TopPerformance"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetTopPerformanceQuery } = topPerformance;

export const topDeveloper = createApi({
  reducerPath: "topDeveloper",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Top Developer"],
  endpoints: (builder) => ({
    getTopDeveloper: builder.query({
      query: ({ count, endDate, startDate }) => ({
        url: `${Apis.topDeveloper}`,
        params: {
          count,
          startDate,
          endDate,
        },
      }),
      providesTags: ["TopDeveloper"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetTopDeveloperQuery } = topDeveloper;

export const summaryApi = createApi({
  reducerPath: "summary",
  baseQuery: baseQueryWithAuth,
  tagTypes: ["Summary"],
  endpoints: (builder) => ({
    getSummaryOfBusiness: builder.query({
      queryFn: async ({ startDate, endDate, value }) => {
        try {
          const { data } = await API_AXIOS.post(Apis.summaryOfEOI, {
            startDate,
            endDate,
            value,
          });

          return { data: data?.data };
        } catch (error) {
          return {
            error: error.response ? error.response.data : error.message,
          };
        }
      },
      providesTags: ["Summary"],
      keepUnusedDataFor: 300,
    }),
  }),
});

export const { useGetSummaryOfBusinessQuery } = summaryApi;
