import { Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { getAdvanceSearch } from "../../store/reducers/queryData";
import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import BasicSelect from "../BasicSelect";
import LoadButton from "../LoadButton";
import { jsonSort, teamUsers } from "../../utils/commonMethods";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setQuery } from "../../store/reducers/query";
import {
  booking,
  bookingEntryStatus,
  businessStatus,
  // developers,
  leadType,
  paymentMode,
  paymentStatus,
  token,
} from "../../utils/menuItems";
import Apis from "../../utils/apis";
import dayjs from "dayjs";
import MultiSelect from "../MultiSelect";

const BookingAdForm = ({ handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { auth } = useSelector((state) => state.auth);
  const { users } = useSelector((state) => state.users);
  const { teams } = useSelector((state) => state.teams);
  const { query } = useSelector((state) => state.query);
  const { developers } = useSelector((state) => state.developers);
  const agent = auth?.role === "agent";
  const teamLeadAgent = auth?.role === "team_lead" || auth?.role === "agent";
  const AMTeamLeadAgent = teamLeadAgent || auth?.role === "assistant_manager";
  const ManagerAMTeamLeadAgent = AMTeamLeadAgent || auth?.role === "manager";
  const SrmManagerAMTeamLeadAgent =
    ManagerAMTeamLeadAgent || auth?.role === "sr_manager";

  const [values, setValues] = useState({
    type: query?.type ? query?.type : "",
    name: query?.name ? query?.name : "",
    status: query?.status ? query?.status : "",
    clientName: query?.clientName ? query?.clientName : "",
    code: query?.code ? query?.code : "",
    mobile: query?.mobile ? query?.mobile : "",
    clientEmail: query?.clientEmail ? query?.clientEmail : "",
    relationshipManager: query?.relationshipManager
      ? query?.relationshipManager
      : "",
    developer: query?.developer ? query?.developer : [],
    projectName: query?.projectName ? query?.projectName : "",
    unit: query?.unit ? query?.unit : "",
    inputStatus: query?.inputStatus ? query?.inputStatus : [],
    paymentStatus: query?.paymentStatus ? query?.paymentStatus : [],
    paymentMode: query?.paymentMode ? query?.paymentMode : [],
    token: query?.token ? query?.token : "",
    startDate: query?.startDate
      ? dayjs(query?.startDate).startOf("day")
      : dayjs(Date.now()).subtract(2, "days").startOf("day"),
    endDate: query?.startDate
      ? dayjs(query?.endDate).endOf("day")
      : dayjs(Date.now()).endOf("day"),
    srManager: query?.srManager ? query?.srManager : [],
    manager: query?.manager ? query?.manager : [],
    assistantManager: query?.assistantManager ? query?.assistantManager : [],
    teamLead: query?.teamLead ? query?.teamLead : [],
    agent: query?.agent ? query?.agent : [],
  });

  const filterUsers = useCallback(
    (value) => {
      return jsonSort(
        teamUsers(teams, users)?.filter((user) =>
          user.role === value ? true : false
        ),
        "name",
        true
      ).map((user) => ({ value: user._id, label: user.name }));
    },
    [teams, users]
  );

  const modifiedDevelopers = useMemo(() => {
    return developers?.map((dev) => ({ value: dev._id, label: dev.name }));
  }, [developers]);

  return (
    <Formik
      initialValues={values}
      onSubmit={async (values) => {
        if (values.code && values.mobile) {
          values = {
            ...values,
            clientMobile: `${values.code}-${values.mobile}`,
          };
        }
        setLoading(true);
        try {
          // const { type } = await dispatch(
          //   getAdvanceSearch({ apiEndpoint: Apis.filterBookings, values })
          // );
          dispatch(setQuery({ values, type: "booking" }));
          setLoading(false);
          // if (type === `lead/filterLeads/fulfilled`) {
          navigate(`/bookings`);
          //popup Close
          handleClose();
          // }
        } catch (err) {
          console.log("err", err);
        }
      }}
    >
      {({ values, handleChange, setFieldValue, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container rowSpacing={3} columnSpacing={3} mt={0}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Start Date
              </Typography>
              <DatePicker
                value={values.startDate}
                onChange={(newValue) => setFieldValue("startDate", newValue)}
                sx={{ width: "100%" }}
                placeholder="start"
                format="DD/MM/YYYY"
                slotProps={{
                  field: {
                    size: "small",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                End Date
              </Typography>
              <DatePicker
                value={values.endDate}
                onChange={(newValue) => setFieldValue("endDate", newValue)}
                sx={{ width: "100%" }}
                placeholder="start"
                format="DD/MM/YYYY"
                slotProps={{
                  field: {
                    size: "small",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Status
              </Typography>
              <BasicSelect
                value={values.status}
                name={"status"}
                size="small"
                handleChange={handleChange}
                items={booking}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Type
              </Typography>
              <BasicSelect
                value={values.type}
                name={"type"}
                size="small"
                handleChange={handleChange}
                items={leadType}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Source
              </Typography>
              <TextField
                name="name"
                value={values.name}
                onChange={handleChange}
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Client Name
              </Typography>
              <TextField
                name="clientName"
                value={values.clientName}
                onChange={handleChange}
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Client Email
              </Typography>
              <TextField
                name="clientEmail"
                value={values.clientEmail}
                onChange={handleChange}
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <>
                <Typography
                  sx={{
                    fontSize: 18,
                    mb: 1,
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  Client Mobile
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Select
                    value={values.code}
                    onChange={handleChange}
                    size="small"
                    name="code"
                    sx={{ minWidth: "4rem" }}
                  >
                    <MenuItem value="1">+1 (USA)</MenuItem>
                    <MenuItem value="44">+44 (UK)</MenuItem>
                    <MenuItem value="91">+91 (IN)</MenuItem>
                    <MenuItem value="971">+971 (UAE)</MenuItem>
                    {/* Add more country codes as needed */}
                  </Select>
                  <TextField
                    name="mobile"
                    type="number"
                    value={values.mobile}
                    onChange={handleChange}
                    placeholder="Enter mobile number"
                    fullWidth
                    size="small"
                  />
                </Box>
              </>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Developer
              </Typography>
              <MultiSelect
                name="developer"
                size="small"
                value={values.developer}
                handleChange={setFieldValue}
                items={modifiedDevelopers}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Relationship Manager
              </Typography>
              <TextField
                name="relationshipManager"
                value={values.relationshipManager}
                onChange={handleChange}
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Project Name
              </Typography>
              <TextField
                name="projectName"
                value={values.projectName}
                onChange={handleChange}
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Unit
              </Typography>
              <TextField
                name="unit"
                value={values.unit}
                onChange={handleChange}
                type="number"
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Booking Status
              </Typography>
              <MultiSelect
                name="inputStatus"
                size="small"
                value={values.inputStatus}
                handleChange={setFieldValue}
                items={bookingEntryStatus}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Payment Status
              </Typography>
              <MultiSelect
                name="paymentStatus"
                size="small"
                value={values.paymentStatus}
                handleChange={setFieldValue}
                items={paymentStatus}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Mode of Payment
              </Typography>
              <MultiSelect
                name="paymentMode"
                size="small"
                value={values.paymentMode}
                handleChange={setFieldValue}
                items={paymentMode}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Typography
                sx={{
                  fontSize: 18,
                  mb: 1,
                  fontWeight: 500,
                  color: "#000000",
                }}
              >
                Token
              </Typography>
              <BasicSelect
                value={values.token}
                name={"token"}
                label={"Token"}
                size="small"
                handleChange={handleChange}
                items={token}
              />
            </Grid>

            {!SrmManagerAMTeamLeadAgent && (
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  sx={{
                    fontSize: 18,
                    mb: 1,
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  Sr Manager
                </Typography>

                <MultiSelect
                  name="srManager"
                  value={values.srManager}
                  handleChange={setFieldValue}
                  items={filterUsers("sr_manager")}
                />
              </Grid>
            )}

            {!ManagerAMTeamLeadAgent && (
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  sx={{
                    fontSize: 18,
                    mb: 1,
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  Team Manager
                </Typography>
                <MultiSelect
                  value={values.manager}
                  name={"manager"}
                  handleChange={setFieldValue}
                  items={filterUsers("manager")}
                />
              </Grid>
            )}
            {!AMTeamLeadAgent && (
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  sx={{
                    fontSize: 18,
                    mb: 1,
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  Assistant Manager
                </Typography>
                <MultiSelect
                  value={values.assistantManager}
                  name={"assistantManager"}
                  handleChange={setFieldValue}
                  items={filterUsers("assistant_manager")}
                />
              </Grid>
            )}
            {!teamLeadAgent && (
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  sx={{
                    fontSize: 18,
                    mb: 1,
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  Team Lead
                </Typography>
                <MultiSelect
                  value={values.teamLead}
                  name={"teamLead"}
                  handleChange={setFieldValue}
                  items={filterUsers("team_lead")}
                />
              </Grid>
            )}
            {!agent && (
              <Grid item xs={12} sm={6} md={4}>
                <Typography
                  sx={{
                    fontSize: 18,
                    mb: 1,
                    fontWeight: 500,
                    color: "#000000",
                  }}
                >
                  Agents
                </Typography>
                <MultiSelect
                  value={values.agent}
                  name={"agent"}
                  handleChange={setFieldValue}
                  items={filterUsers("agent")}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <LoadButton
                loading={loading}
                type="submit"
                variant="contained"
                color="custom"
                sx={{ width: "120px", marginBottom: "15px" }}
              >
                Apply
              </LoadButton>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default BookingAdForm;
